<template>
  <div>
    <PrivacyPersona :persona="persona" />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import PrivacyPersona from "@/views/persone/dettaglio-persona/PrivacyPersona.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "anagrafiche-insegnante",
  components: { PrivacyPersona },
  props: {
    persona: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const id_persona = ref(route.params.id_persona);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Area Riservata");
    });

    return {
      id_persona,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
});
</script>
